import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Modal, Box, CardProps } from '@mui/material';
import '../CSS/DataCard.css'; // Import your CSS styles

interface SubHeader {
  title: string;
  paragraphs: string[];
}

interface StructuredText {
  header: string;
  subHeaders?: SubHeader[];
}

interface DataCardProps extends CardProps {
  title: string;
  text: string;
  structuredText?: StructuredText[];
  imageUrl: string;
  backgroundColor?: string;
}

const DataCard: React.FC<DataCardProps> = ({ title, text, imageUrl, backgroundColor = 'default', structuredText }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    maxHeight: '90vh',
  };

  return (
    <>
      <Card className="data-card" onClick={handleOpen} sx={{ backgroundColor }}> {/* Use className for styling */}
        <CardMedia
          component="img"
          height="180"
          image={imageUrl}
          alt={title}
          className="data-card-image" // Use className for styling
        />
        <CardContent className="data-card-content">
          <Typography gutterBottom variant="h5" component="div" className="data-card-title">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="data-card-text">
            {text}
          </Typography>
        </CardContent>
      </Card>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2 }} className="data-card-header">
            {title}
          </Typography>
          <CardMedia
            component="img"
            height="650"
            image={imageUrl}
            alt={title}
            className="data-card-image" // Reuse for modal image styling
          />
          {structuredText && structuredText.map((section, index) => (
            <Box key={index} mb={2}>
              <Typography variant="h5" component="h5" className="data-card-header">
                {section.header}
              </Typography>
              {section.subHeaders && section.subHeaders.map((subHeader, subIndex) => (
                <Box key={subIndex} mb={2}>
                  <Typography variant="h6" component="h6" className="data-card-subheader">
                    {subHeader.title}
                  </Typography>
                  {subHeader.paragraphs.map((paragraph, pIndex) => (
                    <Typography key={pIndex} variant="body1" className="data-card-paragraph">
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default DataCard;
