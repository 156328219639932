import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoPath = './images/Costcone Logo 2.png';
  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Services', path: '/services' },
    { title: 'Technologies', path: '/technologies' },
    { title: 'Contact', path: '/contactus' },
 
  ];

  const drawer = (
    <List>
      {navLinks.map((item) => (
        <ListItem button key={item.title} component={Link} to={item.path} onClick={handleDrawerToggle}>
          <ListItemText primary={item.title} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <AppBar position="fixed">
      <Toolbar>
        <img src={logoPath} alt="Logo" style={{ height: 20, marginRight: 10 }} />
        <Typography variant="h6" style={{ flexGrow: 1 }}>
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          navLinks.map((item) => (
            <Button key={item.title} color="inherit" component={Link} to={item.path}>
              {item.title}
            </Button>
          ))
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;


