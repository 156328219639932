import React, { useState } from 'react';
import { Box, Button, Typography, Grid, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import '../CSS/CanvasComponent.css'; // Ensure this path is correct

interface CanvasComponentProps {
  backgroundImage: string;
}

const CanvasComponent: React.FC<CanvasComponentProps> = ({ backgroundImage }) => {
  const [activeContent, setActiveContent] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = (content: string) => {
    setActiveContent(activeContent === content ? null : content);
  };

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: isMobile ? '10px' : '20px',
        boxSizing: 'border-box',
      }}
    >
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          {/* Apply 50px spacing before this text */}
          <Typography variant={isMobile ? 'h4' : 'h2'} gutterBottom className="canvas-component-text" sx={{ width: isMobile ? '90%' : '1400px', textAlign: 'center', marginTop: '50px' }}>
            Your long term strategic partner - <span style={{ color: 'rgb(4, 8, 17, 0.897)' }}>engineering solutions to optimise business processes, cost management and operational efficiency accross industries...</span>
          </Typography>
        </Grid>
        <Grid item>
          {/* Apply 50px spacing before this text */}
          <Typography variant={isMobile ? 'h6' : 'h5'} gutterBottom className="canvas-component-text-other" sx={{ width: isMobile ? '90%' : '1200px', textAlign: 'center', marginTop: '50px' }}>
            Explore our vision, mission and business strategy as we pave the way for cutting edge digital solutions
            that create value for your business, empower your people, and delight your customers.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              className="canvas-component-button"
              sx={{ marginTop: '20px' }}
              onClick={() => handleButtonClick('mission')}>
              Mission
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="canvas-component-button"
              sx={{ marginTop: '20px' }}
              onClick={() => handleButtonClick('vision')}>
              Vision
            </Button>
          </Grid>
        </Grid>
        {activeContent && (
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant="body1" className="canvas-component-text-other">
                  {activeContent === 'mission' ? 'Our mission is to provide cutting-edge, accessible, and customizable technology tools and systems that drive significant improvements in productivity and cost-effectiveness. We commit to fostering partnerships across industries, understanding their unique challenges, and delivering tailored solutions that meet their evolving needs. Through our expertise, innovation, and customer-centric approach, we aim to contribute to the success and sustainability of businesses, enabling them to thrive in a competitive global marketplace. At Costcone Global, we are not just a technology provider; we are a strategic ally in the journey towards operational excellence and long-term growth."' : ''}
                  {activeContent === 'vision' ? 'Costcone Global envisions itself as a pioneering force in the global market, revolutionizing industries through innovative technology solutions. Our ambition is to become the premier supplier of cutting-edge tools and systems, driving unprecedented efficiencies in cost management, business processes, and operational performance. We aspire to empower businesses across various sectors with our state-of-the-art solutions, enabling them to achieve not only sustainable growth but also to set new benchmarks in operational excellence. At Costcone Global, we are committed to continuously pushing the boundaries of innovation, ensuring our partners and clients stay at the forefront of their industries. Through our relentless pursuit of excellence and a dedication to transformative technology, we aim to redefine the future of business efficiency, making a significant and positive impact on the global economic landscape ' : ''}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CanvasComponent;
