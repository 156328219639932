// OpenForBusiness.tsx
import React from 'react';
import { Typography, Card, CardContent, Grid, Container } from '@mui/material';

interface DataCardProps {
  title: string;
  email: string;
  image: string; // Added prop for background image URL
}

const DataCard: React.FC<DataCardProps> = ({ title, email, image }) => {
  return (
    <Card 
      sx={{ 
        minWidth: 275, 
        maxWidth: 325, 
        minHeight: 180, 
        maxHeight: 180, 
        margin: 2, 
        marginTop: '50px',
        backgroundColor: '#f5f5f5',
        BackgroundImage: `url(${image})`, // Set the background image
        backgroundSize: 'cover', // Cover the entire card
        backgroundPosition: 'center', // Center the background image
        color: 'white', // Adjust text color for better visibility against a background image
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" className='data-card-subheader'>
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" className='data-card-text'>
          Email: {email}
        </Typography>
        <Typography variant="body2" className='data-card-subheader1'>
          {/* Additional content here if needed */}
        </Typography>
      </CardContent>
    </Card>
  );
};

const OpenForBusiness: React.FC = () => {
  // Example background image URL - replace with your actual URLs
  const BackgroundImage = "/images/Frame2.png";

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom align="center" className="data-card-header" sx={{ marginTop: '70px' }}>
        Open For Business
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Cross-industry and cross-platform spcialists in process automation ready to assist you. Contact us through any of the appropriate channels below.
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        {/* Pass the `image` prop to each DataCard */}
        <Grid item>
          <DataCard title="Inquiries" email="enquiries@costcone.com" image={BackgroundImage}/>
        </Grid>
        <Grid item>
          <DataCard title="Support" email="support@costcone.com" image={BackgroundImage}/>
        </Grid>
        <Grid item>
          <DataCard title="Operations" email="operations@costcone.com" image={BackgroundImage}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OpenForBusiness;


