import React from 'react';
import Typography from '@mui/material/Typography';
import '../CSS/DataCard.css';

interface PageLayoutProps {
    title: string;
    detail: string;
    date: string;
    children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, detail, date, children }) => {
    return (
        <div style={{ marginTop: '30px' }}> {/* Adjust the marginTop value as needed */}
            <Typography variant="h4" component="h4" gutterBottom className="data-card-title">
                <center>{title}</center>
            </Typography>
            <Typography variant="h6" component="h6" gutterBottom>
                {detail}
            </Typography>
            <Typography variant="h6" component="h6" gutterBottom>
                {date}
            </Typography>
            <div>
                {children}
            </div>
        </div>
    );
};

export default PageLayout;

