import React, { useEffect } from 'react';
import ContactUs from '../components/ContactUs'; 
import PageLayout from '../components/PageLayout';
import backgroundImage from '../images/Frame2.png';
import DataCard from '../components/DataCard';
import OpenForBusiness from '../components/OpenForBusiness';
import ChatBox from '../components/ChatBox';

const ContactPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      marginTop: '50px',
      backgroundImage: `url(${backgroundImage})`, // Set the background image
      backgroundSize: 'cover', // Cover the entire page with the image
      backgroundPosition: 'center', // Center the background image
      width: '100%', // Ensure the background image covers the full width
      minHeight: '100vh', // Minimum height to cover the full viewport height
      padding: '20px 0', // Add some padding at the top and bottom
    }}>
      <OpenForBusiness />
    </div>
  );
};

export default ContactPage;
