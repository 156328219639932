import React, { useEffect } from 'react';
import DataCard from '../components/DataCard';
import PageLayout from '../components/PageLayout';
import PricingSection from '../components/PricingSection';

import backgroundImage from '../images/Frame2.png';

const Services: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      marginTop: '20px',
      backgroundImage: `url(${backgroundImage})`, // Set the background image
      backgroundSize: 'cover', // Cover the entire page with the image
      backgroundPosition: 'center', // Center the background image
      width: '100%', // Ensure the background image covers the full width
      minHeight: '100vh', // Minimum height to cover the full viewport height
      padding: '20px 0', // Add some padding at the top and bottom
    }}>
      <PageLayout 
        title="User-Centric Applications, Process Automation, Integrations, Digitial Tranformations...we've got you covered! " 
        detail="" 
        date=""
        children=""
      >
        {/* Content can be added here if PageLayout is designed to hold children */}
      </PageLayout>
      
      {/* Example DataCard - ensure you adjust the structuredText prop to match the updated DataCard component structure if needed */}
      <DataCard 
        title=" Custom Application Development" 
        text="We are experts in transforming your business processes into automation solutions that create efficiencies, and cost savings to your operations." 
        imageUrl="/images/AppDev.png" // Ensure the path is correct
        backgroundColor="#f0f0f0" 
        structuredText={[
          {
            header: "Custom Solution Offerings",
            subHeaders: [
              {
                title: "Front-End Development with React.js:",
                paragraphs: [
                  "Utilize React's component-based architecture to build dynamic and responsive user interfaces.",
                  "Ensure a seamless and engaging user experience across various devices and platforms. ",
                  " Single-Page Applications (SPAs): create fast and fluid SPAs that offer a native-app-like experience, reducing page reloads and improving user engagement.",
                  "Implement state management tools like Redux or Context API to efficiently handle data across different components.",
                  "Integration with APIs and Web Services: Connect the React front end with various RESTful APIs, web services, and third-party services for expanded functionality. ",
                  "Performance Optimization - Leveraging React's virtual DOM for high-performance applications, optimizing rendering and updating processes for faster and smoother user experiences.",
                  "Accessibility and Internationalization - developing applications that are accessible and internationalized, reaching a broader audience and ensuring compliance with global standards."
                ]
              },
              {
                title: "Back-End Development with Express.js",
                paragraphs: [
                  "RESTful API Development: - Design and develop scalable and secure RESTful APIs using Express.js, facilitating effective communication between the front end and database.",
                  "Database Integration -  Integrate with various databases (like Postgress, MySQl, MongoDB, SQL databases) for robust data storage and retrieval.",
                  "Authentication and Authorization - We implement secure user authentication and authorization mechanisms, including OAuth, JWT, and session-based authentication",
                  "Middleware Management: We utilise Express.js other middleware management packages for request handling, enhancing app functionality and performance.",
                  "Error Handling and Logging - we ensure the implementation of comprehensive error handling and logging mechanisms for smoother application maintenance and debugging."
                ]
              },
              {
                title: "Latest Web Technologies and Practices: ",
                paragraphs: [
                  "Responsive Design: Use modern CSS frameworks and techniques to ensure that applications are responsive and mobile-friendly.",
                  "Progressive Web Apps (PWAs): Develop PWAs for a native-like experience, enabling offline usage, push notifications, and faster load times. ",
                  "Web Security Best Practices: Implement the latest web security standards, including HTTPS, data encryption, and protection against common vulnerabilities like XSS and CSRF attacks",
                  "Version Control and DevOps Integration: Utilize Git for version control and integrate with DevOps pipelines for continuous integration and deployment ",
                  "(CI/CD). Cloud Integration and Deployment: Provide support for deploying applications to cloud platforms (like AWS, Azure, or Google Cloud) for scalability and reliability.",
                  "Modern JavaScript Features and Frameworks: Use the latest ES6+ features and incorporate other JavaScript frameworks/libraries as needed. ",
                  " Real-Time Data Handling: Implement WebSocket or similar technologies for real-time data handling in applications requiring live updates."
                ]
              }
            ]
          },
        ]}
      />
       <DataCard 
        title="Business, Manufacturing and Industrial Process Automation" 
        text="We are experts in transforming your business processes into automation solutions that create efficiencies, and cost savings to your operations." 
        imageUrl="/images/bpr1.png" // Ensure the path is correct
        backgroundColor="#f0f0f0" 
        structuredText={[
          {
            header: "Business Process Soluions",
            subHeaders: [
              {
                title: "Automated Workflow Solutions:",
                paragraphs: [
                  "At Costcone Global, we work with industries best-practices and advanced business process automation and management tools. Designing and implementing automated workflows for routine business processes, from simple to very complex processes, facilitating the reduction of manual efforts and enhancing efficiency and transparency within businesses. We leverage the capabilities of workflow development tools such as Nintex Automation Cloud, Power Automate, Automation Anywhere, UIPath and Camunda. When considering building applications for our clients, we review your entire business operations in relation to our deliverables and deliver to specification based on suitability and usability for your business.",
                  "Custom bots designed to automate repetitive, rule-based tasks across various business functions, including finance, HR, customer service, and IT operations. These bots operate 24/7, reducing manual effort and minimizing errors.",
                ]
              },
              {
                title: "Form and Mobile App Development:",
                paragraphs: [
                  "RESTful API Development: Design and develop scalable and secure RESTful APIs using Express.js, facilitating effective communication between the front end and database. Database Integration: Integrate with various databases (like MongoDB, SQL databases) for robust data storage and retrieval. Authentication and Authorization: Implement secure user authentication and authorization mechanisms, including OAuth, JWT, and session-based authentication. Server-Side Rendering: Leverage server-side rendering capabilities for improved SEO and faster initial page loads. Middleware Management: Utilize Express.js middleware for request handling, enhancing app functionality and performance. Error Handling and Logging: Implement comprehensive error handling and logging mechanisms for smoother application maintenance and debugging."
                ]
              },
              {
                title: "Process Mapping and Management",
                paragraphs: [
                  "Responsive Design: Use modern CSS frameworks and techniques to ensure that applications are responsive and mobile-friendly. Progressive Web Apps (PWAs): Develop PWAs for a native-like experience, enabling offline usage, push notifications, and faster load times. Web Security Best Practices: Implement the latest web security standards, including HTTPS, data encryption, and protection against common vulnerabilities like XSS and CSRF attacks. Version Control and DevOps Integration: Utilize Git for version control and integrate with DevOps pipelines for continuous integration and deployment (CI/CD). Cloud Integration and Deployment: Provide support for deploying applications to cloud platforms (like AWS, Azure, or Google Cloud) for scalability and reliability. Modern JavaScript Features and Frameworks: Use the latest ES6+ features and incorporate other JavaScript frameworks/libraries as needed. Real-Time Data Handling: Implement WebSocket or similar technologies for real-time data handling in applications requiring live updates. SEO-Friendly Development: Ensure that applications are built with SEO best practices in mind for higher search engine visibility."
                ]
              }
            ]
          },
          {
            header: "Business Process Management (BPM)",
            subHeaders: [
              {
                title: "End-to-End Process Automation:",
                paragraphs: [
                  "Beyond task automation, Costcone Global delivers comprehensive BPM solutions that automate entire business processes. This holistic approach ensures seamless integration and coordination across different tasks and departments, enhancing overall operational agility.",
                  "Custom bots designed to automate repetitive, rule-based tasks across various business functions, including finance, HR, customer service, and IT operations. These bots operate 24/7, reducing manual effort and minimizing errors.",
                ]
              },
              {
                title: "Digital Workforce Management:",
                paragraphs: [
                  "Utilising this technology, we can help organizations manage their digital workforce by providing tools and strategies to monitor, analyze, and optimize the performance of bots. This ensures that automated processes remain efficient, scalable, and aligned with changing business needs.",
                ]
              },
              {
                title: "Compliance and Governance:",
                paragraphs: [
                  "With a deep understanding of regulatory requirements, Costcone Global and our partners ensure that automated processes are compliant with industry standards and best practices. This includes implementing robust data handling and privacy measures, as well as audit trails for accountability.",
                ]
              }
            ]
          },
          {
            header: "Manufacturing and Industrial Process Solutions",
            subHeaders: [
              {
                title: "Custom SCADA System Development:",
                paragraphs: [
                  "Design and implement custom SCADA systems that fit the unique requirements of each facility, including real-time data collection, process visualization, control functions, and alarm management.",
                  "Seamlessly integrate SCADA systems with existing enterprise resource planning (ERP) systems, Internet of Things (IoT) devices, and other industrial control systems for comprehensive operational oversight.",
                ]
              },
              {
                title: " Industrial Automation Solutions",
                paragraphs: [
                  "Deploy advanced automation solutions to streamline manufacturing processes, improve production efficiency, and reduce downtime using programmable logic controllers (PLCs), robotics, and automated machinery.",
                  "Control System Modernization: Upgrade outdated control systems to modern, efficient platforms that support the latest in automation technology, ensuring compatibility and future scalability."
                ]
              },
              {
                title: " IoT and Connectivity Solutions",
                paragraphs: [
                  " Implement IIoT solutions to enhance connectivity and data exchange between equipment and systems, enabling predictive maintenance, asset tracking, and energy management.",
                  "Utilize wireless sensors for remote monitoring and control, reducing installation costs and improving flexibility in operations.",
                  "",
                ]
              },
              {
                title: "Cybersecurity and Compliance",
                paragraphs: [
                  "Implement robust cybersecurity measures tailored to the industrial environment, protecting SCADA systems and automation networks from cyber threats and unauthorized access.",
                  "Ensure systems are compliant with industry standards and regulations, such as ISA/IEC 62443, to maintain operational integrity and safety.",
                ]
              }
            ]
          },
          {
            header: "Industry-Specific Automation Solutions",
            subHeaders: [
              {
                title: "Manufacturing:",
                paragraphs: [
                  "Solutions focused on improving production line efficiency, quality control, and just-in-time (JIT) manufacturing processes.",
                  
                ]
              },
              {
                title: "Energy and Utilities:",
                paragraphs: [
                  "Systems designed for monitoring and controlling energy production, distribution, and smart grid management.",
                ]
              },
              {
                title: "Water Treatment:",
                paragraphs: [
                  "Automation and monitoring solutions for water and wastewater treatment plants, including process control and environmental compliance.",
                ]
              },
              {
                title: "Oil and Gas:",
                paragraphs: [
                  "Solutions for upstream, midstream, and downstream operations, focusing on safety, efficiency, and remote monitoring of facilities.",
                ]
              }
            ]
          },
          //
        ]}
      />
      <DataCard 
        title="Artificial Intelligence, Internet of Things(IoT), Data Visualisation and Reporting" 
        text="." 
        imageUrl="/images/AppDev.png" // Ensure the path is correct
        backgroundColor="#f0f0f0" 
        structuredText={[
          {
            header: "AI/ML Research and Development",
            subHeaders: [
              {
                title: "Innovative Solutions",
                paragraphs: [
                  "At the core of Costcone Globals mission is the pursuit of groundbreaking AI research, focusing on developing solutions that push the boundaries of what's possible in artificial intelligence, machine learning, and data analytics.",
                  "Development of custom AI models tailored to specific industry needs, enabling predictive maintenance, process optimization, and intelligent automation. ",
                  "Collaborate with leading universities and research institutions to stay at the cutting edge of AI research, ensuring that our solutions benefit from the latest scientific advancements.",
                ]
              },
              {
                title: "AI Infrastructure Services and Provisioning",
                paragraphs: [
                  "We implement AI-driven solutions capable of automating the provision public services, enhancing citizen engagement, streamlining government operations, and improving public safety through intelligent surveillance and anomaly detection systems.",
                  "Deploy AI technologies to support smart city initiatives, including traffic management, waste reduction, energy conservation, and predictive policing, contributing to safer, more sustainable urban environments.",
                  "Provide AI solutions for regulatory compliance, fraud detection, and cybersecurity, ensuring government data is protected against evolving cyber threats.",
                ]
              },
              {
                title: "AI in Manufacturing ",
                paragraphs: [
                  "We can leverage the capabilities of AI/ML models where to optimize manufacturing processes, from predictive maintenance and quality control to supply chain management and demand forecasting, reducing downtime and waste while maximizing efficiency.",
                  "Integrate AI with robotics to automate complex manufacturing tasks, enhancing precision and productivity while reducing the reliance on manual labor. ",
                  "Implement AI systems for real-time monitoring and control of manufacturing operations, enabling immediate adjustments to production processes and minimizing disruptions with our eco-system of technologies,",
                ]
              },
            ]
          },

          {
            header: "Industrial Integration and Capabilities",
            subHeaders: [
              {
                title: "Industrial IoT (IIoT) Integration",
                paragraphs: [
                  "Combine AI with IIoT to gather and analyze data from across industrial operations, offering insights that drive smarter decision-making, improve asset utilization, and enhance operational efficiency.",

                ]
              },
              {
                title: "Energy Management",
                paragraphs: [
                  "Utilize AI for energy management, optimizing consumption across facilities and leveraging renewable energy sources more effectively, leading to cost savings and reduced environmental impact.",
                
                ]
              },
              {
                title: "Safety and Compliance:",
                paragraphs: [
                  "Apply AI to monitor and ensure compliance with safety standards and regulations, using predictive analytics to identify potential risks and prevent accidents before they occur.",
                  
                ]
              },
            ]
          },
        ]}
      />

      <DataCard 
        title="Strategic Planning, Advisory, Contracts, Delivery Management Services" 
        text="We are experts in transforming your business processes into automation solutions that create efficiencies, and cost savings to your operations." 
        imageUrl="/images/Delivery.png" // Ensure the path is correct
        backgroundColor="#f0f0f0"
        structuredText={[
          {
            header: "Consulting and Advisory Services",
            subHeaders: [
              {
                title: "Strategic IT Consulting",
                paragraphs: [
                  " We provide strategic guidance on the effective use of technology to achieve business goals, including digital transformation strategies, technology roadmaps, and IT architecture design.",
                  "Operational Efficiency Improvement: ",
                  "Collaborate with leading universities and research institutions to stay at the cutting edge of AI research, ensuring that our solutions benefit from the latest scientific advancements.",
                ]
              },
              {
                title: "Operational Efficiency Improvement: ",
                paragraphs: [
                  "Our experts analyze your business processes to identify inefficiencies and recommend solutions leveraging technology to streamline operations, reduce costs, and improve service delivery. ",
                ]
              },
              {
                title: "Technology Selection and Suitability Analysis",
                paragraphs: [
                  "W We help clients navigate the complex technology landscape, advising on the selection of software, hardware, and platforms that best suit their specific operational needs and strategic objectives.",
                ]
              },
            ]
          },
          {
            header: "Delivery Management Services",
            subHeaders: [
              {
                title: "Project and Program Management",
                paragraphs: [
                  " Our delivery management services ensure that IT projects are completed on time, within budget, and in alignment with business objectives, using proven methodologies and best practices.",
                  
                  "Collaborate with leading universities and research institutions to stay at the cutting edge of AI research, ensuring that our solutions benefit from the latest scientific advancements.",
                ]
              },
              {
                title: "Agile Transformation Services: ",
                paragraphs: [
                  "Our experts analyze your business processes to identify inefficiencies and recommend solutions leveraging technology to streamline operations, reduce costs, and improve service delivery. ",
                ]
              },
              {
                title: "Vendor and Contract Management:",
                paragraphs: [
                  " We help clients navigate the complex technology landscape, advising on the selection of software, hardware, and platforms that best suit their specific operational needs and strategic objectives.",
                ]
              },
            ]
          },
          {
            header: "Technology Solutions and Integration",
            subHeaders: [
              {
                title: "Custom Software Development:",
                paragraphs: [
                  "Create bespoke software solutions tailored to the unique requirements of each client, from enterprise applications to specialized tools for data analysis, workflow automation, and more.",
                  "Collaborate with leading universities and research institutions to stay at the cutting edge of AI research, ensuring that our solutions benefit from the latest scientific advancements.",
                ]
              },
              {
                title: "System Integration Services:",
                paragraphs: [
                  "Seamlessly integrate disparate technology systems and platforms, enabling data flow and functionality across organizational silos for improved decision-making and operational efficiency.",
                ]
              },
              {
                title: "Cloud Solutions and Migration:",
                paragraphs: [
                  "Guide clients through the migration to cloud-based solutions, selecting the appropriate cloud services (IaaS, PaaS, SaaS) and managing the transition process to ensure minimal disruption.",
                ]
              },
            ]
          },
        ]}
      />
      

      {/* Additional DataCard components for other services */}

      <PricingSection />
    </div>
  );
};

export default Services;
