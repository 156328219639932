import React, {useEffect} from 'react';
import '../CSS/Home.css';
import PageLayout from '../components/PageLayout';
import DataCard from '../components/DataCard';
import CanvasComponent from '../components/CanvasComponent';
import backgroundImage from '../images/Frame1.png';
import backgroundImage2 from '../images/Frame2.png';
import companypageLogo from '../images/Frame13.png';
import sideImage from '../images/contracts.png';
import CompanyComponent from '../components/CompanyComponent';
import Grid from '@mui/material/Grid';
import PageText from '../components/PageText';





const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const textList = ["Your Long term strategic partner - from business improvement to enterprise integration.", "Creating Efficiencies, transparency and cost-savings for your business.", "Facilitating Collaborations and enabling your people to work smarter"]; // Replace with actual texts
  const bulletPoints = [
    "Business Process Automation(BPA), Robotics Process Automation (RPA), Artificial Intelligence and Machine Learning (AI/ML) driven enterprise solutions.",
    "Computer Vision, Supervisory Control and Data Acquistion (SCADA), Manufacturing Process Solutions",
    "AI-Powered Automation Solutions, AI Model Training and Fine Tuning",
    "Advanced Analytics and Business Intelligence"
  ];
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    <br>
    </br>
    <br>
    </br>
    <div>
      <CanvasComponent backgroundImage= {backgroundImage} />
      </div>
      <div>
      <CompanyComponent 
      backgroundImage= {backgroundImage2} // Replace with your actual image path
      headerText="Our Company" 
      bodyText="At Costcone Global Services, we represent innovation, strategic focus, and efficiency in cost management and reduction. By leveraging advanced technologies in process automation, data analytics, artificial intelligence (AI), machine learning(ML), and strategic planning, we aim to revolutionize cost management and operational efficiency for businesses across various sectors. Delivering bespoke solutions designed to create efficiencies, and reduce operational and production costs for business."
      bodyText1="In today's rapidly evolving business landscape, both private and public sector enterprises are increasingly recognizing the necessity for streamlined processes, enhanced transparency, cost optimization, informed decision making, and effective collaboration with customers and partners. Costcone Global delivers solutions to facilitate these efficiently - our team of business technology experts are both highly technical and commercially aware, and are focused on ensuring the suitability and usability of business technologies to delight our customers. We can deliver bespoke solutions, or extend the capabilities of your existing platforms."
      bulletPoints={bulletPoints}
      sideImage= {sideImage}
    />

    
      </div>
      <div></div>
      <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', backgroundColor: '#e0e0e0' }}>
    <DataCard 
          title="Bespoke Business, Manaufacturing and Industrial Process Applications." 
          text="We pride ourselves in delivering solutions that do exactly what you want, exactly how you imagined it. Where your requirements demand the need for a custom solution, we are prepared to take the challenge and deliver. Speak to Us. Our Operations team is more than happy to build you a picture"
          imageUrl="/images\AppDev.png"
          backgroundColor="#f0f0f0" // Example background color
          structuredText={[
            {
              header: "Power Platform, SharePoint/Office365 , and Azure Cloud Based Solutions",
              subHeaders: [
                {
                  title: "HR- Starters, Leavers and Movers Solutions",
                  paragraphs: [
                    " End to end recruitment, and onboarding solutions to manage Starters integration to the business, facilitates Movers accross the business and effectively disboarding Leavers",
                  ]
                },
                {
                  title: "Electronic Forms and Process Development",
                  paragraphs: [
                    "Custom Forms Development with automated business processes to suit your business needs, support campaigns, and programs ",
                  ]
                },
                {
                  title: "Real Estate Procurement Management",
                  paragraphs: [
                    "End to end real estate and procurement management solutions",
                  ]
                },
              ]
            },
          ]}
        />
        <DataCard
          title="Business, Manufacturing and Industrial Process Automation" 
          text="We are experts in transforming your business processes in to automation solutions that create efficiencies, transparency and cost savings for your business. Our team of world class automation consultants are here to help." 
          imageUrl="/images/bpr1.png" 
          backgroundColor="#f0f0f0" // Example background color
          structuredText={[
            {
              header: "Business Process Automation",
              subHeaders: [
                {
                  title: "Solution Delivery",
                  paragraphs: [
                    " We provide strategic guidance on the effective use of technology to achieve business goals, including digital transformation strategies, technology roadmaps, and IT architecture design.",
                    "Operational Efficiency Improvement: ",
                  ]
                },
                {
                  title: "Operational Efficiency Improvement: ",
                  paragraphs: [
                    "Our experts analyze your business processes to identify inefficiencies and recommend solutions leveraging technology to streamline operations, reduce costs, and improve service delivery. ",
                  ]
                },
                {
                  title: "Technology Selection and Suitability Analysis",
                  paragraphs: [
                    "We help clients navigate the complex technology landscape, advising on the selection of software, hardware, and platforms that best suit their specific operational needs and strategic objectives.",
                  ]
                },
              ]
            },
          ]}
        />
        <DataCard
          title="Artificial Intelligence/ Machine Learning(AI/ML), Internet of Things(IoT)." 
          text="Stay on top of your Business Strategies, Campaigns, Research and Development. We deliver a comprehensive AI/ML model integration service." 
          imageUrl="/images/BusinessIntel.png" 
          backgroundColor="#f0f0f0" // Example background color
          structuredText={[
            {
              header: "AI Interation, Research and Development",
              subHeaders: [
                {
                  title: "Innovative Solutions",
                  paragraphs: [
                    "At the core of Costcone Globals mission is the pursuit of groundbreaking AI research, focusing on developing solutions that push the boundaries of what's possible in artificial intelligence, machine learning, and data analytics.",
                    "We engage in the development of custom AI models tailored to specific industry needs, enabling predictive maintenance, process optimization, and intelligent automation. ",
                    "Collaborate with leading universities and research institutions to stay at the cutting edge of AI research, ensuring that our solutions benefit from the latest scientific advancements.",
                  ]
                },
                {
                  title: "AI Services for Government ",
                  paragraphs: [
                    "Our experts analyze your business processes to identify inefficiencies and recommend solutions leveraging technology to streamline operations, reduce costs, and improve service delivery. ",
                  ]
                },
                {
                  title: "Technology Selection and Suitability Analysis",
                  paragraphs: [
                    "We help clients navigate the complex technology landscape, advising on the selection of software, hardware, and platforms that best suit their specific operational needs and strategic objectives.",
                  ]
                },
              ]
            },
          ]}
        />
        <DataCard
          title="Advisory, Contracts, Delivery Management Services" 
          text="Our Consulting, Advisory, Delivery Management, and Service operations are designed to leverage client engagement with cutting-edge technology solutions, ensuring optimal fit and functionality for businesses across various industries." 
          imageUrl="/images/Delivery.png" 
          backgroundColor="#f0f0f0" // Example background color
          structuredText={[
            {
              header: "Client Engagement and Experience",
              subHeaders: [
                {
                  title: "Customer Experience Strategy",
                  paragraphs: [
                    "We can help you in determining strategies to enhance your customer experience across all touchpoints, leveraging technology to create seamless, personalized interactions that drive engagement and loyalty.",
        
                  ]
                },
                {
                  title: "Change Management and Adoption: ",
                  paragraphs: [
                    "We help our clients ensure the successful implementation of new technologies with comprehensive change management support, focusing on employee training, stakeholder communication, and organizational readiness. ",
                  ]
                },
              ]
            },
            {
              header: "Delivery Management Services",
              subHeaders: [
                {
                  title: "Project and Program Management",
                  paragraphs: [
                    "Our delivery management services ensure that IT projects are completed on time, within budget, and in alignment with business objectives, using proven methodologies and best practices.",
        
                  ]
                },
                {
                  title: "Agile Transformation Services: ",
                  paragraphs: [
                    "We support businesses in adopting agile methodologies for improving project flexibility, and accelerating delivery times, all while maintaining high-quality outputs. ",
                  ]
                },
                {
                title: "Vendor and Contract Management:",
                paragraphs: [
                  "We Assist our clients with the selection and management of technology vendors and contracts, ensuring favorable terms and alignment with business needs are attained. ",
                ]
                },
              ]
            },
            {
              header: "Technology Solutions and Development",
              subHeaders: [
                {
                  title: "Custom Software Development",
                  paragraphs: [
                    " Our primary operation is to create bespoke software solutions tailored to the unique requirements of each client, from enterprise applications to specialized tools for data analysis, workflow automation, industrial process automations and more.",
        
                  ]
                },
                {
                  title: "System Integration Services: ",
                  paragraphs: [
                    "Seamlessly integrate disparate technology systems and platforms, enabling data flow and functionality across organizational silos for improved decision-making and operational efficiency. ",
                  ]
                },
                {
                title: "Cloud Solutions and Migration",
                paragraphs: [
                  "Guide and assist clients through the migration to cloud-based solutions, selecting the appropriate cloud services (IaaS, PaaS, SaaS) and managing the transition process to ensure minimal disruption. ",
                ]
                },
              ]
            },
          ]}
        />
    </Grid>
      <DataCard title="Power Platform - PowerApps, Automate, Power BI" 
        text="By leveraging the capabilities of the Microsoft Power Platform, we provide versatile solutions that integrate seamlessly with existing business systems. This platform allows us to create applications that enhance data analysis, automate processes, and foster collaboration. Power BI, Power Apps, and Power Automate are instrumental in turning complex business data into actionable insights, empowering decision-makers with real-time information. PowerApps for your business offers a range of benefits, particularly in streamlining operations, enhancing productivity, and enabling greater agility in responding to business needs. " 
        imageUrl="/images\PowerPlatform.png"
      />
      <DataCard 
        title="Nintex Automation Cloud" 
        text="Costcone Global's comprehensive service offering in Nintex workflow and technologies is designed to empower businesses to automate their processes, enhance operational efficiency, and drive digital transformation. By leveraging the intuitive and powerful capabilities of Nintex, Costcone Global delivers end-to-end solutions that streamline workflows, improve compliance, and enable businesses to achieve their strategic goals." 
        imageUrl="/images\NintexCloud.png" 
      />
      <DataCard 
        title="Automation Anywhere" 
        text="By integrating Automation Anywhere, we bring the power of Robotic Process Automation (RPA) to businesses. This technology enables the automation of repetitive, rule-based tasks, freeing up valuable human resources for more strategic work. RPA with Automation Anywhere not only increases efficiency but also reduces the likelihood of human error, leading to more consistent and reliable outcomes for your business" 
        imageUrl="/images\AutomationAnywhere.png" 
      />
      <DataCard 
        title="ICONICS Suite 10.97.3 - Genesis64 "   
        text="GENESIS64™ is an advanced HMI SCADA solution suite designed for Microsoft operating systems. ICONICS GENESIS64 suite delivers unparalleled performance with OPC, BACnet, Modbus, and open standard database connectivity. The GENESIS64 suite provides connectivity from plant floor SCADA and building facilities to corporate business systems. Designed to leverage 64-bit and OPC UA technologies, GENESIS64 allows operators, executives, and IT professionals to integrate real-time manufacturing, energy, and business information into a secure and unified web-enabled visualization dashboard."
        //
        imageUrl="/images\Gen64.png" 
      />
    </div>
  );
};

export default Home;

