import React,  { useEffect } from 'react';
import DataCard from '../components/DataCard';
import PageLayout from '../components/PageLayout';
import PowerPlatform from '../images/PowerPlatform.png';
import AutomationAnywhere from '../images/AutomationAnywhere.png';
import NintexCloud from '../images/NintexCloud.png'; // Ensure you have this import
import Gen64 from '../images/GEN64.png';
import Camunda from '../images/Camunda.png'; // Ensure you have this import

import backgroundImage from '../images/Frame2.png';

const Technologies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      marginTop: '20px',
      backgroundImage: `url(${backgroundImage})`, // Set the background image
      backgroundSize: 'cover', // Cover the entire page with the image
      backgroundPosition: 'center', // Center the background image
      width: '100%', // Ensure the background image covers the full width
      minHeight: '100vh', // Minimum height to cover the full viewport height
      padding: '20px 0', // Add some padding at the top and bottom
    }}>
      <PageLayout title="Building an Ecosystem of User-Centric Applications. Supporting you through your digital transformation and process automation challenges." detail="" date="" children="" />
      <DataCard 
        title="ICONICS Suite 10.97.3 - Genesis64 " 
        text="GENESIS64™ is an advanced HMI SCADA solution suite designed for Microsoft operating systems. ICONICS GENESIS64 suite delivers unparalleled performance with OPC, BACnet, Modbus, and open standard database connectivity. The GENESIS64 suite provides connectivity from plant floor SCADA and building facilities to corporate business systems. Designed to leverage 64-bit and OPC UA technologies, GENESIS64 allows operators, executives, and IT professionals to integrate real-time manufacturing, energy, and business information into a secure and unified web-enabled visualization dashboard"
        imageUrl={Gen64}
        structuredText={[
          {
            header: "Custom SCADA Solutions with ICONICS GENESIS64",
            subHeaders: [
              {
                title: "Tailored Automation and Control:",
                paragraphs: [
                  "Utilizing the robust framework of ICONICS GENESIS64, Costcone Global designs and implements customized SCADA systems that automate, monitor, and control industrial processes. These solutions are engineered to meet the specific requirements of each client, ensuring optimal performance, reliability, and scalability."
                ]
              },
              {
                title: "Real-time Data Visualization and Monitoring:",
                paragraphs: [
                  "By harnessing the powerful visualization tools within ICONICS GENESIS64, Costcone Global provides clients with real-time insights into their operations. This includes dynamic data dashboards, 3D and geospatial visualizations, and comprehensive monitoring capabilities that enhance decision-making and operational awareness."
                ]
              },
              {
                title: "Energy Management and Sustainability: ",
                paragraphs: [
                  " Costcone Global leverages ICONICS GENESIS64 to develop energy management solutions that help businesses monitor and reduce their energy consumption, carbon footprint, and operational costs. These solutions support sustainability goals while ensuring compliance with environmental regulations and standards."
                ]
              }
            ]
          },
          {
            header: "Integration and Connectivity",
            subHeaders: [
              {
                title: "System Integration:",
                paragraphs: [
                  "Recognizing the importance of seamless integration, Costcone Global ensures that its ICONICS GENESIS64-based SCADA systems integrate flawlessly with existing enterprise systems, IoT devices, and other industrial controls. This holistic approach enhances data flow and operational coherence across the entire business ecosystem."
                ]
              },
              {
                title: "IoT and Edge Computing:",
                paragraphs: [
                  "Costcone Global utilizes the IoT and edge computing capabilities of ICONICS GENESIS64 to extend the reach of SCADA systems. By connecting a wide array of devices and sensors, clients can gather more granular data from the edge of their network, unlocking new insights and efficiencies."
                ]
              },
            ]
          },
          {
            header: "Advanced Analytics and AI",
            subHeaders: [
              {
                title: "Predictive Analytics: ",
                paragraphs: [
                  "Through advanced analytics features of ICONICS GENESIS64, we can provide predictive maintenance solutions that anticipate equipment failures and schedule maintenance proactively. This minimizes downtime and extends the lifespan of critical assets."
                ]
              },
              {
                title: "AI-Enhanced Operations:",
                paragraphs: [
                  "Incorporating artificial intelligence and machine learning algorithms, Costcone Global  enhances the operational intelligence of SCADA systems, enabling automated anomaly detection, optimization recommendations, and process improvements by leveraging the capaibilities of the Genesis64 Platform."
                ]
              },
            ]
          },
          {
            header: "Security and Compliance",
            subHeaders: [
              {
                title: "Robust Security Measures: ",
                paragraphs: [
                  " In an era where cyber threats are prevalent, Costcone Global prioritizes the security of SCADA systems. By implementing comprehensive security protocols and leveraging the secure architecture of ICONICS GENESIS64, Costcone Global ensures the protection of critical industrial systems and data."
                ]
              },
              {
                title: "Regulatory Compliance:",
                paragraphs: [
                  "Our solutions adhere to industry-specific regulatory requirements and standards, ensuring that clients' SCADA systems are compliant and up-to-date with legal obligations."
                ]
              },
            ]
          },
          {
            header: "Training, Support, and Continuous Improvement",
            subHeaders: [
              {
                title: "Expert Training and Empowerment:",
                paragraphs: [
                  "We offers specialized training programs for clients, empowering their teams to effectively manage and optimize their SCADA systems."
                ]
              },
              {
                title: "Continuous Support and Evolution: ",
                paragraphs: [
                  "With ongoing support and maintenance services, Costcone Global ensures that SCADA systems remain operational, efficient, and aligned with evolving business needs. Regular updates and enhancements leverage the latest features of ICONICS GENESIS64 to keep clients at the forefront of industrial automation."
                ]
              },
            ]
          },
          // More sections as needed
        ]}
      />
      <DataCard 
        title="Power Platform - PowerApps, Automate, Power BI" 
        text="Leveraging the Microsoft Power Platform, we provide versatile solutions that integrate seamlessly with existing business systems. This platform allows us to create applications that enhance data analysis, automate processes, and foster collaboration. Power BI, Power Apps, and Power Automate are instrumental in turning complex business data into actionable insights, empowering decision-makers with real-time information."
        imageUrl={PowerPlatform}
        structuredText={[
          {
            header: "PowerApps Development",
            subHeaders: [
              {
                title: "Custom Business Applications",
                paragraphs: [
                  "Specializing in low-code development, Costcone Global utilizes PowerApps to craft custom applications tailored to meet specific business needs.",
                  "These solutions range from simple to complex applications, designed to be accessible across multiple devices and platforms, ensuring a broad reach and user engagement.",
                  "Integrating with other Microsoft services such as Dynamics 365, Office 365, and Azure enhances its capabilities, providing a seamless experience for users and extending the functionality of the applications developed."
                ]
              },
              {
                title: "Rapid Deployment",
                paragraphs: [
                  "By leveraging the capabilities of Power BI, we are able to facilitate data integration across multiple data sources both internal business data and external third-party data sources to create Advanced Analytics and data reporting, deploying interactive business intelligence solutions to facilitate strategic decision-making within your business.",
                  "With a focus on agility, PowerApps allows Costcone Global to quickly move from concept to delivery, enabling businesses to adapt to market changes or internal "
                ]
              },
              {
                title: "Integration Capabilities",
                paragraphs: [
                  "Leveraging the seamless integration of PowerApps with other Microsoft services like Dynamics 365, Office 365, and Azure, Costcone Global enhances the functionality and user experience of the applications developed, creating a cohesive ecosystem of business tools."
                ]
              }
            ]
          },
          {
            header: "Power BI",
            subHeaders: [
              {
                title: "Advanced Data Reporting:",
                paragraphs: [
                  "Through Power BI, Costcone Global provides businesses with the tools to integrate and analyze data from multiple sources, both internal and external. This capability allows for the creation of comprehensive analytics and reports, offering actionable insights for strategic decision-making.",
              
                ]
              },
              {
                title: "Interactive Business Intelligence:",
                paragraphs: [
                  "Deploying interactive dashboards and reports, Costcone Global helps businesses to visualize complex data in an easily digestible format, facilitating better understanding and faster decision-making processes.",
                  "With a focus on agility, PowerApps allows Costcone Global to quickly move from concept to delivery, enabling businesses to adapt to market changes or internal "
                ]
              },
              {
                title: "AI Powered Analytics and Integration",
                paragraphs: [
                  "Leveraging the seamless integration of PowerApps with other Microsoft services like Dynamics 365, Office 365, and Azure, Costcone Global enhances the functionality and user experience of the applications developed, creating a cohesive ecosystem of business tools."
                ]
              }
            ]
          },
          {
            header: "Power Automate for Process Automation",
            subHeaders: [
              {
                title: "Workflow Automation:",
                paragraphs: [
                  "Costcone Global leverages Power Automate to design and implement automated workflows for routine and complex business processes. This reduces manual efforts, enhances efficiency, and ensures transparency within business operations.",
              
                ]
              },
              {
                title: "Process Optimization:",
                paragraphs: [
                  "Tailored solutions by Costcone Global optimize critical business functions such as HR, finance, legal, and compliance processes. By automating these functions, businesses can achieve regulatory compliance, streamline operations, and realize significant time and cost savings.",
                  "With a focus on agility, PowerApps allows Costcone Global to quickly move from concept to delivery, enabling businesses to adapt to market changes or internal "
                ]
              },
              {
                title: "AI Powered Analytics and Integration",
                paragraphs: [
                  "Leveraging the seamless integration of PowerApps with other Microsoft services like Dynamics 365, Office 365, and Azure, Costcone Global enhances the functionality and user experience of the applications developed, creating a cohesive ecosystem of business tools."
                ]
              }
            ]
          },
          {
            header: "Comprehensive Integration and Customization",
            subHeaders: [
              {
                title: "Microsoft Ecosystem Integration:",
                paragraphs: [
                  "Costcone Global's expertise extends to integrating Power Platform solutions with the broader Microsoft ecosystem, enhancing data flow, and functionality across business operations.",
                ]
              },
              {
                title: "Custom Code Solutions:",
                paragraphs: [
                  "Where out-of-the-box solutions meet their limits, Costcone Global employs custom code injections to extend the capabilities of Power Platform solutions. This includes API integration with other systems, web service calls, or handling large datasets, ensuring that unique business requirements are met with precision.",
                  "With a focus on agility, PowerApps allows Costcone Global to quickly move from concept to delivery, enabling businesses to adapt to market changes or internal "
                ]
              },
            ]
          },
          {
            header: "Continuous Support and Evolution",
            subHeaders: [
              {
                title: "Ongoing Support and Maintenance:",
                paragraphs: [
                  "we will provides continuous technical support, ensuring that the applications remain up-to-date with the latest technology trends and security patches. Regular updates and user feedback incorporation ensure that the solutions evolve to meet changing business needs",
                  "",
                ]
              },
              {
                title: "Strategic Advisory Services:",
                paragraphs: [
                  "Beyond technical implementation, Costcone Global offers strategic advisory services to help businesses align their Power Platform strategy with their overall business objectives, ensuring long-term success and ROI.",
                  "By offering these comprehensive Power Platform services, Costcone Global stands at the forefront of enabling businesses to harness the full potential of digital transformation, driving operational excellence, and fostering a culture of continuous improvement and innovation.",
                ]
              },
            ]
          },
        ]}
      />

      {/* Similar updates for other DataCard components */}
      <DataCard 
        title="Nintex Automation Cloud" 
        text="Our use of Nintex positions us uniquely to offer robust workflow automation solutions. Nintex’s intuitive tools enable the design and deployment of automated processes that significantly reduce manual intervention. This not only accelerates task completion but also minimizes human error, leading to more efficient and reliable business operations." 
        imageUrl={NintexCloud}
        structuredText={[
          {
            header: "Nintex Workflow Automation",
            subHeaders: [
              {
                title: "Process Mapping and Optimization:",
                paragraphs: [
                  "Costcone Global begins with a thorough analysis of the client's business processes to identify areas ripe for automation. By mapping out workflows, the team can pinpoint inefficiencies and develop a strategic plan to implement Nintex workflows that streamline operations and enhance productivity.",
                ]
              },
              {
                title: "Custom Workflow Development:",
                paragraphs: [
                  "Utilizing the Nintex Workflow platform, we develop customized workflows tailored to the unique needs of each business. These workflows automate routine tasks, from simple approvals to complex operational processes, reducing manual intervention and accelerating task completion.",
                  "With a focus on agility, Nintex techniologies allows us to quickly move from concept to delivery, enabling businesses to adapt to market changes or internal "
                ]
              },
              {
                title: "Integration with Existing Systems:",
                paragraphs: [
                  "Nintex workflows designed by Costcone Global seamlessly integrate with a wide range of systems, including CRM, ERP, and document management systems. This ensures that data flows smoothly across platforms, enhancing the overall efficiency and effectiveness of business operations."
                ]
              }
            ]
          },
          {
            header: "Nintex Forms and Mobile Solutions",
            subHeaders: [
              {
                title: "Custom Forms Development:",
                paragraphs: [
                  "Alongside workflow automation, Costcone Global leverages Nintex Forms to create custom, responsive forms that capture critical business data. These forms are designed to work across devices, ensuring accessibility and enhancing user engagement.",
                ]
              },
              {
                title: "Mobile Application Deployment:",
                paragraphs: [
                  "Recognizing the increasing need for mobility, Costcone Global deploys Nintex mobile solutions that allow employees to access, complete, and submit forms from any location, ensuring business continuity and flexibility.",

                ]
              },
            ]
          },
          {
            header: "Nintex RPA (Robotic Process Automation)",
            subHeaders: [
              {
                title: "Robotic Process Automation:",
                paragraphs: [
                  "To further enhance operational efficiency, Costcone Global implements Nintex RPA solutions, automating repetitive, rule-based tasks. This frees up valuable human resources for more strategic work, increases process speed, and reduces the likelihood of errors.",
                  
                ]
              },
              {
                title: "RPA Bot Development:",
                paragraphs: [
                  "Costcone Global develops and deploys RPA bots that interact with web and desktop applications, automating data entry, extraction, and processing tasks. These bots are tailored to the specific operational needs of the client, providing scalable and flexible automation solutions.",

                ]
              },
            ]
          },
          {
            header: "Compliance and Governance",
            subHeaders: [
              {
                title: "Regulatory Compliance:",
                paragraphs: [
                  "With a deep understanding of regulatory requirements across industries, Costcone Global ensures that Nintex workflows and forms adhere to compliance standards, reducing risk and enhancing governance.",
                  
                ]
              },
              {
                title: "Document Generation and Management:",
                paragraphs: [
                  "Leveraging Nintex Document Generation, Costcone Global automates the creation, storage, and management of business documents, ensuring accuracy, compliance, and easy retrieval.",

                ]
              },
            ]
          },
          {
            header: "Strategic Advisory and Consulting",
            subHeaders: [
              {
                title: "Digital Transformation Strategy:",
                paragraphs: [
                  "We are determined at Costcone Global to  work closely with clients to align their Nintex automation initiatives with broader digital transformation goals, ensuring that thier technology investments deliver measurable business value.",
                  
                ]
              },
              {
                title: "Continuous Improvement",
                paragraphs: [
                  "By monitoring the performance of Nintex solutions and gathering feedback, Costcone Global identifies opportunities for further optimization and enhancement, fostering a culture of continuous improvement.",

                ]
              },
            ]
          },
        ]}
      />
      <DataCard 
        title="Automation Anywhere" 
        text="By integrating Automation Anywhere, we bring the power of Robotic Process Automation (RPA) to businesses. This technology enables the automation of repetitive, rule-based tasks, freeing up valuable human resources for more strategic work. RPA with Automation Anywhere not only increases efficiency but also reduces the likelihood of human error, leading to more consistent and reliable outcomes for your business."
        imageUrl={AutomationAnywhere}
        structuredText={[
          {
            header: "Robotic Process Automation (RPA) Solutions",
            subHeaders: [
              {
                title: "Custom Bot Development:",
                paragraphs: [
                  "We specialize in integrating disparate data sources, providing a unified and accessible data environment. This integration ensures that decision-makers have real-time access to critical business insights, enabling informed decision-making.",
                  "Custom bots designed to automate repetitive, rule-based tasks across various business functions, including finance, HR, customer service, and IT operations. These bots operate 24/7, reducing manual effort and minimizing errors.",
                ]
              },
              {
                title: "Intelligent Automation:",
                paragraphs: [
                  "By integrating AI and machine learning capabilities of Automation Anywhere, we can enhance bots with cognitive functions, which gives room to enableing the processing of unstructured data, decision-making based on historical patterns, and the handling of complex, non-routine tasks."
                ]
              },
              {
                title: "Process Discovery and Optimization:",
                paragraphs: [
                  "Utilizing Automation Anywhere's process discovery tools, we can identify and map out high-impact processes suitable for automation within your business. This strategic approach ensures that automation initiatives are aligned with business priorities, maximizing ROI."
                ]
              }
            ]
          },
          {
            header: "Business Process Management (BPM)",
            subHeaders: [
              {
                title: "End-to-End Process Automation:",
                paragraphs: [
                  "Beyond task automation, Costcone Global delivers comprehensive BPM solutions that automate entire business processes. This holistic approach ensures seamless integration and coordination across different tasks and departments, enhancing overall operational agility.",
                  "Custom bots designed to automate repetitive, rule-based tasks across various business functions, including finance, HR, customer service, and IT operations. These bots operate 24/7, reducing manual effort and minimizing errors.",
                ]
              },
              {
                title: "Digital Workforce Management:",
                paragraphs: [
                  "Utilising this technology, we can help organizations manage their digital workforce by providing tools and strategies to monitor, analyze, and optimize the performance of bots. This ensures that automated processes remain efficient, scalable, and aligned with changing business needs.",
                ]
              },
              {
                title: "Compliance and Governance:",
                paragraphs: [
                  "With a deep understanding of regulatory requirements, Costcone Global and our partners ensure that automated processes are compliant with industry standards and best practices. This includes implementing robust data handling and privacy measures, as well as audit trails for accountability.",
                ]
              }
            ]
          },
          // More sections as needed
        ]}
      />
      
      {/* Repeat for Automation Anywhere and Camunda DataCard components with appropriate structuredText content */}

    </div>
  );
};

export default Technologies;
