import React from 'react';
import './index.css';
import './App.css'; // Ensure this imports Tailwind's base styles if not done elsewhere
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Pages/Home';
import Services from './Pages/Services';
import Technologies from './Pages/Technologies';
import Partners from './Pages/Partners';
import Company from './Pages/Company';  
import Careers from './Pages/Careers';
import ContactPage from './Pages/ContactUs';


const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen"> {/* Example of Tailwind usage */}
        <Header />
        <main className="flex-grow"> {/* Helps with footer at bottom */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contactus" element={<ContactPage />} />
            <Route path="*" element={<div className="text-center text-xl mt-5">404 Not Found</div>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
