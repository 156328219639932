// Footer.tsx
import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0, mt: 5 }}>
      <Container maxWidth="md">
        <Toolbar>
          <Typography variant="body1" color="inherit" style={{ flexGrow: 1 }}>
            © {currentYear} Costcone Global Services. All rights reserved.
          </Typography>
          <Typography variant="body2" color="inherit">
          
          </Typography>
        </Toolbar>
      </Container>
    </AppBar> 
  );
}

export default Footer;
